.App {
    text-align: center;
    font-weight: 600;
}

.card-signin {
    width: 100%;
    max-width: 500px;
    margin: auto;
    margin-top: 120px;
    box-shadow: 0 20px 27px rgb(0 0 0 / 5%);
    border-radius: 12px;
    font-weight: 600;
}

.card-signin h5 {
    text-align: center;
    font-size: 24px;
    font-weight: 600;
    color: #141414;
    margin: 0px;
}

.text-center {
    text-align: center;
}

.text-muted {
    color: #8c8c8c;
}

.layout-default {
    background-color: transparent;
    color: #fff;
}

.brand {
    vertical-align: middle;
    margin-left: 3px;
}

.layout-dashboard {
    background: transparent;
    text-align: left;
    position: relative;
    overflow-x: hidden;
}

.layout-dashboard .ant-layout-sider.sider-primary {
    width: 270px;
    left: 0;
    z-index: 99;
    height: auto;
    overflow: auto;
    margin: 0;
    padding: 33px 20px;
    background: #f5f5f5;
}
.layout-dashboard .ant-layout-sider.sider-primary .brand img {
    height: 30px;
}
.layout-dashboard .ant-layout-sider.sider-primary hr {
    margin: 18px 0;
    border: none;
    height: 1px;
    background-color: #bfbfbf;
}

.layout-dashboard .ant-layout-sider.sider-primary .brand {
    font-weight: 600;
}
.layout-dashboard .ant-layout-sider.sider-primary .brand span {
    vertical-align: middle;
    margin-left: 3px;
}

.layout-dashboard .ant-layout-sider.sider-primary .ant-layout-sider-children {
    width: auto;
}

.layout-dashboard .ant-layout-sider.sider-primary .ant-menu-inline {
    border: none;
}

.layout-dashboard .ant-layout-sider.sider-primary .ant-menu-inline .ant-menu-item,
.layout-dashboard .ant-layout-sider.sider-primary .ant-menu-inline .ant-menu-submenu {
    margin: 0;
    overflow: visible;
}

.layout-dashboard .ant-layout-sider.sider-primary .ant-menu-item,
.layout-dashboard .ant-layout-sider.sider-primary .ant-menu-submenu {
    padding: 0 !important;
    height: auto;
    line-height: normal;
}

.layout-dashboard .ant-layout-sider.sider-primary .ant-menu-item.ant-menu-item-selected .active,
.layout-dashboard .ant-layout-sider.sider-primary .ant-menu-item .active,
.layout-dashboard .ant-layout-sider.sider-primary .ant-menu-submenu.ant-menu-item-selected .active,
.layout-dashboard .ant-layout-sider.sider-primary .ant-menu-submenu .active {
    background-color: #fff;
    box-shadow: 0 20px 27px rgb(0 0 0 / 5%);
    font-weight: 600;
}

.layout-dashboard .ant-layout-sider.sider-primary .ant-menu-item a,
.layout-dashboard .ant-layout-sider.sider-primary .ant-menu-submenu a {
    padding: 10px 16px;
    color: #141414;
    border-radius: 8px;
    display: flex;
    align-items: center;
    white-space: nowrap;
}

.layout-dashboard
    .ant-layout-sider.sider-primary.ant-layout-sider-primary
    .ant-menu-item.ant-menu-item-selected
    .active
    .icon,
.layout-dashboard .ant-layout-sider.sider-primary.ant-layout-sider-primary .ant-menu-item .active .icon {
    background-color: #21dbcd;
}

.layout-dashboard .ant-layout-sider.sider-primary .ant-menu-item .icon,
.layout-dashboard .ant-layout-sider.sider-primary .ant-menu-submenu .icon {
    display: inline-flex;
    width: 32px;
    height: 32px;
    background-color: #fff;
    box-shadow: 0 4px 6px rgb(0 0 0 / 12%);
    border-radius: 6px;
    justify-content: center;
    align-items: center;
    margin-right: 11px;
    vertical-align: middle;
}

.layout-dashboard .ant-layout-sider.sider-primary .ant-menu-item.ant-menu-item-selected .active svg path,
.layout-dashboard .ant-layout-sider.sider-primary .ant-menu-item .active svg path,
.layout-dashboard .ant-layout-sider.sider-primary .ant-menu-submenu.ant-menu-item-selected .active svg path,
.layout-dashboard .ant-layout-sider.sider-primary .ant-menu-submenu .active svg path {
    fill: #fff;
}
.layout-dashboard .ant-layout-sider.sider-primary .ant-menu-item svg path,
.layout-dashboard .ant-layout-sider.sider-primary .ant-menu-submenu svg path {
    fill: #21dbcd;
}

.layout-dashboard .ant-layout-sider.sider-primary .ant-menu-inline .ant-menu-item.menu-item-header {
    padding: 10px 16px !important;
    color: #8c8c8c;
    font-weight: 700;
    font-size: 13px;
    text-transform: uppercase;
    display: block;
}

.layout-dashboard .ant-layout {
    background: transparent;
    position: relative;
    min-height: 100vh;
    width: 100%;
    flex-shrink: 0;
}

:where(.css-dev-only-do-not-override-k7429z).ant-divider-horizontal {
    margin: 0 0;
}

.dashboard {
    margin-left: 20px;
    margin-top: 88px;
}

.main-layout {
    padding: 32px;
}

.card-layout {
    width: 100%;
    max-width: 1024px;
    padding: 0 32px;
    text-align: center;
    margin: auto;
    margin-top: 28px;
    margin-bottom: 20px;
    font-weight: 600;
}

.create-chatbot-box {
    width: 100%;
    margin: auto;
    margin-bottom: 32px;
    max-width: 580px;
    padding: 12px 24px;
    border: #8c8c8c 1px solid;
    font-weight: normal;
    text-align: left;
    font-size: 16px;
}

.pdf-view {
    max-width: 180px;
    max-height: 240px;
    margin: auto;
}

.input-button {
    display: flex;
    flex-direction: row;
    flex: auto;
}

.sbtn-widget {
    text-align: right;
}

.margin-top-24 {
    margin-top: 24px;
}

.text-left {
    text-align: left;
}

.text-right {
    text-align: right;
}

.title {
    font-size: 32px;
}

.font-weight-normal {
    font-weight: normal;
}

.margin-32 {
    margin: 32px 0px;
}

.chatbot-card-list {
    margin: auto;
    display: flex;
    justify-content: space-around;
    align-items: flex-start;
}

.limits {
    text-align: left;
    margin: 0 120px;
    margin-left: 60px;
}

.limit-title {
    margin-top: 40px;
    font-size: 32px;
    font-weight: 600;
}

.limit-subtitle {
    margin-top: 16px;
    font-size: 24px;
}

.subscritions {
    margin: 24px auto;
    max-width: 800px;
}

.sub-container {
    display: flex;
    justify-content: space-around;
    align-items: center;
}

.sub-detail {
    min-width: 200px;
    margin: 12px auto;
}

.subscription-alert {
    width: 80%;
    max-width: 480px;
    margin: auto;
    text-align: left;
}

.chatbot-card {
    width: 170px;
    margin: 20px;
    cursor: pointer;
}

.chatbot-card-image {
    width: 170px;
    height: 210px;
    padding: 25px;
    background: #d3d3d3;
}

.delete-icon {
    width: 30px;
    height: 30px;
    background-color: #fee2e2;
    border: none;
    border-radius: 20px;
    margin: 15px 10px;
    padding: 5px;
    color: #e45454;
}

.width-100 {
    width: 100%;
}

.code-block {
    background: #f5f5f5;
    padding: 8px;
    font-size: 13px;
}

.history-block {
    border: 1px solid #d3d3d3;
    padding: 16px;
    font-weight: normal;
    cursor: pointer;
}

.history-block:hover,
.history-block:active {
    background: #f5f5f5;
}

.history-title {
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    color: #8c8c8c;
}

.history-time {
    color: #8c8c8c;
    float: right;
}

.history-content {
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
}

.history {
    display: flex;
    flex-direction: column;
    border: 1px solid #d3d3d3;
    padding: 0px 12px;
    font-weight: normal;
    height: 600px;
    border-radius: 6px;
    overflow: auto;
    position: relative;
    text-align: left;
    max-height: 600px;
}

.chat-content {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    overflow: auto;
    margin-top: 8px;
}

.bot-text {
    background: #f5f5f5;
    color: #141414;
    border-radius: 6px;
    align-self: flex-start;
    text-align: left;
    padding: 0 12px;
    max-width: 90%;
    margin: 12px 0px;
}

.customer-text {
    background: #21dbcd;
    color: #fff;
    border-radius: 6px;
    align-self: flex-end;
    text-align: left;
    padding: 0 12px;
    max-width: 90%;
    margin: 12px 0px;
}

.question-input {
    margin: 12px;
    border-radius: 6px;
}

.question-container {
    position: absolute;
    bottom: 8px;
    width: -webkit-fill-available;
    padding: 0 12px 0 0;
}

.suggestmsg-container {
    position: absolute;
    bottom: 56px;
    width: -webkit-fill-available;
    padding: 0 12px 0 0;
    overflow: auto;
}

.sources {
    text-align: left;
}

.source-text {
    color: #3f3f3f;
    font-weight: normal;
}

.settings-subtitle {
    margin: 4px 0;
    color: #3f3f3f;
}

.settings-title {
    margin-top: 40px;
    font-size: 20px;
}

.settings-content {
    font-weight: normal;
    color: #8c8c8c;
}

.message-credit {
    position: absolute;
    bottom: 64px;
    color: #8c8c8c;
}

.display-flex {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

.margin-12 {
    margin: 12px 0;
}

.align-center {
    align-items: center;
}

.float-right {
    float: right;
}

.profile-image {
    width: 32px;
    height: 32px;
    border-radius: 50%;
    overflow: hidden;
}

.button-image {
    width: 48px;
    height: 48px;
    border-radius: 50%;
    overflow: hidden;
}

.input-white .ant-input {
    background-color: transparent;
    color: white;
}

.input-black .ant-input {
    background-color: transparent;
    color: #2e2d2c;
}

.suggestmsg {
    background: #f5f5f5;
    border-color: #f5f5f5;
    margin: 4px 2px;
    padding: 4px;
}

.width-60 {
    width: 60%;
}

.margin-auto {
    margin: auto;
}

.widget {
    width: 90vw;
    height: 92vh;
    display: flex;
    flex-direction: column;
    border: none;
    padding: 0px 12px;
    font-weight: normal;
    overflow: auto;
    position: relative;
    text-align: left;
    margin: auto;
    margin-top: 1vh;
}

.text-dark {
    color: #21dbcd;
}

.dot-flashing {
    position: relative;
    width: 10px;
    height: 10px;
    border-radius: 5px;
    background-color: #21dbcd;
    color: #21dbcd;
    animation: dot-flashing 1s infinite linear alternate;
    animation-delay: 0.5s;
    margin: 18px 16px;
}
.dot-flashing::before,
.dot-flashing::after {
    content: '';
    display: inline-block;
    position: absolute;
    top: 0;
}
.dot-flashing::before {
    left: -15px;
    width: 10px;
    height: 10px;
    border-radius: 5px;
    background-color: #21dbcd;
    color: #21dbcd;
    animation: dot-flashing 1s infinite alternate;
    animation-delay: 0s;
}
.dot-flashing::after {
    left: 15px;
    width: 10px;
    height: 10px;
    border-radius: 5px;
    background-color: #21dbcd;
    color: #21dbcd;
    animation: dot-flashing 1s infinite alternate;
    animation-delay: 1s;
}

@keyframes dot-flashing {
    0% {
        background-color: #21dbcd;
    }
    50%,
    100% {
        background-color: rgba(33, 219, 205, 0.2);
    }
}

.default-setting-padding {
    padding: 0 64px 16px 64px;
}

.sider-button {
    position: fixed;
    top: 10px;
    left: 10px;
    z-index: 999;
    border: none;
    background: transparent;
}

.sider-primary.collapsed .close-button {
    display: none;
}

.close-button {
    position: absolute;
    top: 10px;
    right: 10px;
    z-index: 999;
    border: none;
    background: transparent;
}

.layout {
    margin-left: 292px;
}

.dashboard-title {
    font-size: 60px;
}

.dashboard-des {
    font-size: 32px;
}

.normal-text {
    overflow-wrap: break-word;
}

.normal-component {
    white-space: normal;
}

.interface-col {
    width: 50%;
}

.conversation-col-1 {
    width: 40%;
}

.conversation-col-2 {
    width: 60%;
}

.score-span {
    border: 1px solid #1a9a9a;
    background-color: #bff7f4;
    color: #1a9a9a;
    padding: 2px 4px 2px 4px;
    border-radius: 2px;
    display: inline-block;
    font-size: 12px;
    margin-top: 12px;
    font-weight: 500;
}

.cover {
    position: fixed;
    text-align: center;
    width: calc(100vw - 292px);
    height: 100vh;
    background: rgb(33, 219, 205);
    z-index: 100;
}

.container {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 100;
    animation: loadingD 1.5s 0.3s cubic-bezier(0.17, 0.37, 0.43, 0.67) infinite;
}

.main-loading {
    position: fixed;
    text-align: center;
    width: calc(100vw - 292px);
    height: 100vh;
    background: rgb(255, 255, 255, 0.7);
    z-index: 100;
}

.loading-text-1 {
    position: absolute;
    z-index: 100;
    top: 40%;
    left: 50%;
    transform: translate(-50%, -50%);
    color: #fff;
    font-size: 20px;
    font-weight: 600;
}

.loading-text-2 {
    position: absolute;
    z-index: 100;
    top: 58%;
    left: 50%;
    transform: translate(-50%, -50%);
    color: #fff;
    font-size: 20px;
    font-weight: 600;
}

.lds-roller {
    display: inline-block;
    position: relative;
    width: 80px;
    height: 80px;
}
.lds-roller div {
    animation: lds-roller 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
    transform-origin: 40px 40px;
}
.lds-roller div:after {
    content: ' ';
    display: block;
    position: absolute;
    width: 7px;
    height: 7px;
    border-radius: 50%;
    background: #fff;
    margin: -4px 0 0 -4px;
}
.lds-roller div:nth-child(1) {
    animation-delay: -0.036s;
}
.lds-roller div:nth-child(1):after {
    top: 63px;
    left: 63px;
}
.lds-roller div:nth-child(2) {
    animation-delay: -0.072s;
}
.lds-roller div:nth-child(2):after {
    top: 68px;
    left: 56px;
}
.lds-roller div:nth-child(3) {
    animation-delay: -0.108s;
}
.lds-roller div:nth-child(3):after {
    top: 71px;
    left: 48px;
}
.lds-roller div:nth-child(4) {
    animation-delay: -0.144s;
}
.lds-roller div:nth-child(4):after {
    top: 72px;
    left: 40px;
}
.lds-roller div:nth-child(5) {
    animation-delay: -0.18s;
}
.lds-roller div:nth-child(5):after {
    top: 71px;
    left: 32px;
}
.lds-roller div:nth-child(6) {
    animation-delay: -0.216s;
}
.lds-roller div:nth-child(6):after {
    top: 68px;
    left: 24px;
}
.lds-roller div:nth-child(7) {
    animation-delay: -0.252s;
}
.lds-roller div:nth-child(7):after {
    top: 63px;
    left: 17px;
}
.lds-roller div:nth-child(8) {
    animation-delay: -0.288s;
}
.lds-roller div:nth-child(8):after {
    top: 56px;
    left: 12px;
}
@keyframes lds-roller {
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(360deg);
    }
}

.main-lds-roller {
    display: inline-block;
    position: relative;
    width: 80px;
    height: 80px;
}
.main-lds-roller div {
    animation: main-lds-roller 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
    transform-origin: 40px 40px;
}
.main-lds-roller div:after {
    content: ' ';
    display: block;
    position: absolute;
    width: 7px;
    height: 7px;
    border-radius: 50%;
    background: #21dbcd;
    margin: -4px 0 0 -4px;
}
.main-lds-roller div:nth-child(1) {
    animation-delay: -0.036s;
}
.main-lds-roller div:nth-child(1):after {
    top: 63px;
    left: 63px;
}
.main-lds-roller div:nth-child(2) {
    animation-delay: -0.072s;
}
.main-lds-roller div:nth-child(2):after {
    top: 68px;
    left: 56px;
}
.main-lds-roller div:nth-child(3) {
    animation-delay: -0.108s;
}
.main-lds-roller div:nth-child(3):after {
    top: 71px;
    left: 48px;
}
.main-lds-roller div:nth-child(4) {
    animation-delay: -0.144s;
}
.main-lds-roller div:nth-child(4):after {
    top: 72px;
    left: 40px;
}
.main-lds-roller div:nth-child(5) {
    animation-delay: -0.18s;
}
.main-lds-roller div:nth-child(5):after {
    top: 71px;
    left: 32px;
}
.main-lds-roller div:nth-child(6) {
    animation-delay: -0.216s;
}
.main-lds-roller div:nth-child(6):after {
    top: 68px;
    left: 24px;
}
.main-lds-roller div:nth-child(7) {
    animation-delay: -0.252s;
}
.main-lds-roller div:nth-child(7):after {
    top: 63px;
    left: 17px;
}
.main-lds-roller div:nth-child(8) {
    animation-delay: -0.288s;
}
.main-lds-roller div:nth-child(8):after {
    top: 56px;
    left: 12px;
}
@keyframes main-lds-roller {
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(360deg);
    }
}

@media (max-width: 960px) {
    .interface-col {
        width: 100%;
    }

    .conversation-col-1,
    .conversation-col-2 {
        width: 100%;
    }
}

@media (min-width: 768px) {
    .sider-button {
        display: none;
    }
    .close-button {
        display: none;
    }
}

@media (max-width: 467px) {
    .sub-container {
        display: inline-block;
    }
}

@media (max-width: 767px) {
    .dashboard {
        margin-left: 0;
    }
    .cover {
        width: 100vw;
    }
    .layout-dashboard .ant-layout-sider.sider-primary {
        padding: 0 0px;
    }

    .layout-dashboard .ant-layout-sider.sider-primary .ant-layout-sider-children {
        width: 250px;
        margin: 33px 20px 0 20px;
    }

    .layout {
        margin-left: 0;
    }

    .dashboard {
        margin-top: 66px;
    }

    .dashboard-title {
        font-size: 45px;
    }

    .dashboard-des {
        font-size: 24px;
    }

    .chatbot-card {
        width: 140px;
        margin: 16px;
        cursor: pointer;
    }

    .chatbot-card-image {
        width: 140px;
        height: 175px;
        padding: 20px;
        background: #d3d3d3;
    }

    .interface-col {
        width: 50%;
    }

    .conversation-col-1 {
        width: 40%;
    }

    .conversation-col-2 {
        width: 60%;
    }
}

@media (max-width: 640px) {
    .interface-col {
        width: 100%;
    }

    .conversation-col-1,
    .conversation-col-2 {
        width: 100%;
    }
}
